/*
 *  Radius
*/
/*
 * Config
 * @ Variables
 *
*/
/*
 *  Use Defaults
*/
/*
 *  FWAD base Font size
*/
/*
 * H1
*/
/*
 * header fonts mobile devices
 */
/*
 * header fonts tablets(portrait)
 */
/*
 * header fonts tablets(landscape) and small desktop
 */
/*
 * header fonts large desktop
 */
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*/*
 * body-2
*/
/*
 * body-3
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ar-1
*/
/*/*
 * body-ar-2
*/
/*
 * body-ar-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ru-1
*/
/*/*
 * body-ru-2
*/
/*
 * body-ru-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-zh-1
*/
/*/*
 * body-zh-2
*/
/*
 * body-zh-3
*/
/*
* z-index variables
*/
/*
* form placeholder color variables
*/
/*
 * Background Image
 */
/*
  * Line height font
  */
/*
  * Border Radius
  */
/*
  * Box Sizing
  */
/*
  * Box Shadow
  */
/*
  * Border Radius Separate
  */
/*
  * Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * large screen Desktop (PC's)
  */
/*
  * Wide screen Devices
  */
/*
  * upto Tablet
  */
/*
  * Tablet
  */
/*
  * TabletOnly
  */
/*
  * Mobile
  */
/*
  * Transition
     usage example
       a{
       color: gray;
       @include transition(color .3s ease);
       &:hover {
         color: black;
       }
 }
  */
/*
  * Opacity
  */
/*
  * Clearfix
  */
/*
  * Configure vendor
  */
/*
  * Transform
  */
/**
 *   Use Arrow inside the :after and :before pseudo elements
 *   and change the position as per needed
 *   @include arrows('right', 7px, 7px, #000);
 **/
/**
 *   Background gradient mixin
 **/
/*
  * Flex
  */
/*
 * @ Fonts
 *
*/
/*
 * Base
 * @ Colors
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
.c-header-revamp-component .revamp-header-container .c-header-top-icon span {
  display: flex;
  gap: 8px;
}
.c-header-revamp-component .revamp-header-container .tooltipOpen {
  color: #ffec00 !important;
}
.c-header-revamp-component .revamp-header-container .tooltipOpen a,
.c-header-revamp-component .revamp-header-container .tooltipOpen p,
.c-header-revamp-component .revamp-header-container .tooltipOpen span {
  color: #ffec00 !important;
}
.c-header-revamp-component .revamp-header-container .tooltipOpen a svg,
.c-header-revamp-component .revamp-header-container .tooltipOpen p svg,
.c-header-revamp-component .revamp-header-container .tooltipOpen span svg {
  stroke: #ffec00 !important;
}
.c-header-revamp-component .revamp-header-container .tooltipOpen a svg path,
.c-header-revamp-component .revamp-header-container .tooltipOpen p svg path,
.c-header-revamp-component .revamp-header-container .tooltipOpen span svg path {
  stroke: #ffec00 !important;
}
.c-header-revamp-component .revamp-header-container .tooltipOpen svg {
  stroke: #ffec00 !important;
}
.c-header-revamp-component .revamp-header-container .tooltipOpen svg path {
  stroke: #ffec00 !important;
}
.c-header-revamp-component .revamp-header-container .tooltipOpen svg.dropdown {
  transform: rotate(180deg);
}
.c-header-revamp-component .revamp-header-container .header-revamp-tooltip {
  position: relative;
}
.c-header-revamp-component .revamp-header-container .header-revamp-tooltip .revamp-header-nav-link-dropdown svg {
  width: 21px;
  height: 20px;
}
.c-header-revamp-component .revamp-header-container .header-revamp-tooltip .revamp-header-nav-link-dropdown.tooltipOpen svg {
  stroke: #ffec00 !important;
  transform: rotate(180deg);
}
.c-header-revamp-component .revamp-header-container .header-revamp-tooltip .revamp-header-nav-link-dropdown.tooltipOpen svg path {
  stroke: #ffec00 !important;
}
.c-header-revamp-component .revamp-header-container .header-revamp-dropdown {
  border-top: 4px solid #ffec00;
  background: #ffffff;
  position: absolute;
  z-index: 10;
  top: 40px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  /* < 768px */
}
.c-header-revamp-component .revamp-header-container .header-revamp-dropdown .header-revamp-list-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.c-header-revamp-component .revamp-header-container .header-revamp-dropdown .helper-upcoming {
  display: flex;
  padding: 0px 4px 1px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background-color: #e50000;
  color: white;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-container .header-revamp-dropdown.profile-dropdown-mini-header {
    width: 176px;
    padding: 8px 0px;
    top: 46px !important;
  }
  .c-header-revamp-component .revamp-header-container .header-revamp-dropdown.profile-dropdown-mini-header .header-revamp-list {
    width: 100%;
  }
  .c-header-revamp-component .revamp-header-container .header-revamp-dropdown.language-mini-header-mobile {
    width: 181px;
    padding: 8px 0px;
    top: 41px !important;
  }
  .c-header-revamp-component .revamp-header-container .header-revamp-dropdown.language-mini-header-mobile .header-revamp-list {
    width: 100%;
  }
}
.c-header-revamp-component .revamp-header-container .header-revamp-dropdown.language-mini-header-mobile {
  top: 44px !important;
}
.c-header-revamp-component .revamp-header-container .header-revamp-dropdown.profile-dropdown-mini-header {
  top: 44px !important;
}
.c-header-revamp-component .revamp-header-container .header-revamp-list {
  color: #000000;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.c-header-revamp-component .revamp-header-container .header-revamp-list .logout-revamp-list {
  width: 21px;
  height: 20px;
}
.c-header-revamp-component .revamp-header-container .header-revamp-list.en {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
}
.lang-ar .c-header-revamp-component .revamp-header-container .header-revamp-list.logout-list-item {
  justify-content: revert;
}
.lang-ar .c-header-revamp-component .revamp-header-container .header-revamp-list.logout-list-item .logout-revamp-list {
  transform: rotate(180deg);
  gap: 8px;
}
.lang-ar .c-header-revamp-component .revamp-header-container .header-revamp-list.logout-list-item .logout-revamp-list .logout-icon {
  width: 21px;
  height: 20px;
}
.c-header-revamp-component .revamp-header-container .header-revamp-list.logout-list-item a:hover {
  color: #333333;
}
.c-header-revamp-component .revamp-header-container .header-revamp-list.selected {
  font-weight: 700;
}
.c-header-revamp-component .revamp-header-container .c-header-top-icon {
  width: unset;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top {
  display: flex;
  padding: 8px 35px 8px 200px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top {
    background: #000000;
  }
  .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header {
    background: #333333;
  }
}
.lang-ar .c-header-revamp-component .revamp-header-container .revamp-header-top {
  padding: 8px 200px 8px 35px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-in-user-header {
  gap: 4px;
  color: #ffec00;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-in-user-header {
    gap: 0px;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-in-user-header .user-name {
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-in-user-header svg {
  width: 24px;
  height: 24px;
  stroke: #FFEC00;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-in-user-header svg path {
  stroke: #FFEC00;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-out-user-header svg {
  width: 24px;
  height: 24px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header {
  /* < 768px */
  /* Medium devices (desktops, 1024px and up) */
  background-color: #333333;
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header {
    padding: 0px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header {
    padding: 20px 75px;
  }
}
.upgrade--page .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .upgrade--page .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header {
    padding: 8px 75px;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .redirect-btn {
  color: #ffffff;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0px;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .redirect-btn {
    padding: 0px;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .redirect-btn svg {
  width: 21px;
  height: 20px;
}
.lang-ar .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .redirect-btn svg {
  transform: rotate(180deg);
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .dark-logo picture {
  width: 85px;
  height: 40px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .dark-logo picture img {
  padding: 0;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .logged-in-user-header svg {
  width: 24px;
  height: 24px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu {
  width: 50%;
  display: flex;
  gap: 24px;
  justify-content: end;
  align-items: center;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu {
    gap: 4px;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .header-miniCart {
  top: calc(100% + 9px);
  right: 3px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}
.lang-ar .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .header-miniCart {
  left: 0px;
  right: auto;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .header-miniCart .c-b2c-cart-wrapper {
  border-top: 4px solid #ffec00;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content {
  display: flex;
  flex-direction: column;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section {
  padding: 24px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .ticket-validity {
  margin-top: 10px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .product-details {
  padding-bottom: 20px;
}
.buy-annual-pass--page .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section {
  display: block !important;
  border: none;
  border-bottom: 1px solid #d3d3d3;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-subsection-container {
  padding-top: 0px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-subsection-container .show .ticket-sub-section {
  margin-bottom: 0px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container {
  border-top: 1px solid #d3d3d3;
}
.buy-annual-pass--page .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container {
  border-top: none;
  padding-bottom: 0px;
  background-color: #f7f7f7;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
  border: none;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .cart-summary-details {
  width: 100%;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .cart-summary-details .cart-price-container .total-price-container {
  padding: 0px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container {
  width: 100%;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container {
  border-top: 0px;
  border-top: none;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .checkout-button {
  padding-bottom: 0px !important;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .checkout-button .btn-primary {
  width: calc(100% - 12px);
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .continue-shopping-cta {
  width: 100%;
  padding: 0px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container span svg {
  width: 24px;
  height: 24px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .total-price-indication {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffec00;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .header-revamp-dropdown {
  top: 32px;
}
.lang-ar .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .header-revamp-dropdown {
  left: 0px;
}
.lang-en .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .header-revamp-dropdown {
  right: 5px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .header-revamp-dropdown.profile-dropdown {
  top: 35px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .revamp-header-nav-link {
  color: #ffffff;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 1px;
  cursor: pointer;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .revamp-header-nav-link.tooltipOpen svg {
  stroke: #ffec00 !important;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .revamp-header-nav-link.tooltipOpen svg path {
  stroke: #ffec00 !important;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .revamp-header-nav-link.tooltipOpen svg#header-dropdown-icon {
  transform: rotate(180deg);
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .search-component .container {
  width: 100%;
}
.c-header-revamp-component .revamp-header-container .revamp-header-bottom-body .header-revamp-list {
  position: relative;
  animation: slideInBottom 0.4s ease-out;
}
@keyframes slideInBottom {
  0% {
    top: 30px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-bottom-body .header-revamp-list .arrow-icon {
  opacity: 0;
  transform: translateX(-20px);
  display: block;
}
.c-header-revamp-component .revamp-header-container .revamp-header-bottom-body .header-revamp-list:hover .arrow-icon {
  opacity: 1;
  transform: translateX(0);
  animation: slideInBounce 0.3s ease-out;
}
@keyframes slideInBounce {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  33% {
    transform: translateX(2px);
    opacity: 0.5;
  }
  63% {
    transform: translateX(5px);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-bottom-body .header-revamp-list:hover {
  background-color: #f8f8f8;
  color: #808080;
  border-radius: 4px;
}
.c-header-revamp-component.mini-header {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component.mini-header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 91;
  }
}

/* < 768px */
@media (min-width: 320px) and (max-width: 767px) {
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section {
    padding: 24px;
    padding-bottom: 0px;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .global-cart-error {
    margin-bottom: 20px;
  }
  .buy-annual-pass--page .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section {
    border: none;
    border-bottom: 1px solid #d3d3d3;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .ticket-validity {
    margin-top: 10px;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-subsection-container .show .early-bird-wrap {
    margin-bottom: 0px;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container {
    border-top: 1px solid #d3d3d3;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container {
    border-top: 0px;
  }
  .buy-annual-pass--page .tenant-fwad .cart-dropdown-wrap .c-b2c-mini-cart {
    height: calc(100vh - 61px) !important;
  }
}
.col-lg-4.col-md-12.col-sm-12.page-scrolled {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .col-lg-4.col-md-12.col-sm-12.page-scrolled {
    top: 55px !important;
  }
  .expanded-mycart .col-lg-4.col-md-12.col-sm-12.page-scrolled {
    top: 0px !important;
  }
}

body.buy-annual-pass--page .headerRevamp .c-purchase-journey .b2c-mini-cart-wrapper {
  position: relative;
}
body.buy-annual-pass--page .tenant-fwad .cart-dropdown-wrap.headerRevamp .c-b2c-mini-cart {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  body.buy-annual-pass--page .tenant-fwad .cart-dropdown-wrap.headerRevamp .c-b2c-mini-cart {
    height: calc(100vh - 61px) !important;
  }
}

/*
 *  Radius
*/
/*
 * Config
 * @ Variables
 *
*/
/*
 *  Use Defaults
*/
/*
 *  FWAD base Font size
*/
/*
 * H1
*/
/*
 * header fonts mobile devices
 */
/*
 * header fonts tablets(portrait)
 */
/*
 * header fonts tablets(landscape) and small desktop
 */
/*
 * header fonts large desktop
 */
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*/*
 * body-2
*/
/*
 * body-3
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ar-1
*/
/*/*
 * body-ar-2
*/
/*
 * body-ar-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ru-1
*/
/*/*
 * body-ru-2
*/
/*
 * body-ru-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-zh-1
*/
/*/*
 * body-zh-2
*/
/*
 * body-zh-3
*/
/*
* z-index variables
*/
/*
* form placeholder color variables
*/
/*
 * Background Image
 */
/*
  * Line height font
  */
/*
  * Border Radius
  */
/*
  * Box Sizing
  */
/*
  * Box Shadow
  */
/*
  * Border Radius Separate
  */
/*
  * Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * large screen Desktop (PC's)
  */
/*
  * Wide screen Devices
  */
/*
  * upto Tablet
  */
/*
  * Tablet
  */
/*
  * TabletOnly
  */
/*
  * Mobile
  */
/*
  * Transition
     usage example
       a{
       color: gray;
       @include transition(color .3s ease);
       &:hover {
         color: black;
       }
 }
  */
/*
  * Opacity
  */
/*
  * Clearfix
  */
/*
  * Configure vendor
  */
/*
  * Transform
  */
/**
 *   Use Arrow inside the :after and :before pseudo elements
 *   and change the position as per needed
 *   @include arrows('right', 7px, 7px, #000);
 **/
/**
 *   Background gradient mixin
 **/
/*
  * Flex
  */
/*
 * @ Fonts
 *
*/
/*
 * Base
 * @ Colors
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
/* < 768px */
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-fwad .revamp-header-notice-component {
    display: flex;
    padding: 12px 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  .tenant-fwad .revamp-header-notice-component.c-cookies {
    z-index: unset;
  }
  .tenant-fwad .revamp-header-notice-component .revamp-header-note {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    /* Mobile/Body/Regular/xs */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 183.333% */
    letter-spacing: 0px;
  }
  .tenant-fwad .revamp-header-notice-component span svg {
    width: 25px;
    height: 24px;
  }
  .tenant-fwad .revamp-header-notice-component .close-icon {
    width: 24px;
    height: 24px;
  }
  .tenant-fwad .revamp-header-notice-component.component {
    margin-bottom: 0px;
  }
}
.c-header-revamp-component .revamp-header-notice-component {
  display: flex;
  padding: 12px 35px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.c-header-revamp-component .revamp-header-notice-component span svg {
  width: 25px;
  height: 24px;
}
.c-header-revamp-component .revamp-header-notice-component p {
  color: #000000;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
}
.c-header-revamp-component .revamp-header-notice-component p a {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}
.c-header-revamp-component .revamp-header-notice-component p a:hover {
  color: inherit;
}
.c-header-revamp-component .revamp-header-notice-component .revamp-header-note {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 8px;
}
.c-header-revamp-component .revamp-header-notice-component .revamp-header-note p {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-header-revamp-component .revamp-header-notice-component .close-icon {
  width: 25px;
  height: 24px;
}
.c-header-revamp-component .revamp-header-top {
  background-color: #333333;
}
.c-header-revamp-component .revamp-header-top .revamp-header-openinghours-container .revamp-header-openinghours {
  display: flex;
  cursor: pointer;
  gap: 4px;
}
.c-header-revamp-component .revamp-header-top .revamp-header-openinghours-container .revamp-header-openinghours .right-chevron-icon {
  height: 20px;
  width: 20px;
}
.lang-ar .c-header-revamp-component .revamp-header-top .revamp-header-openinghours-container .revamp-header-openinghours .right-chevron-icon {
  transform: rotate(180deg);
}
.c-header-revamp-component .revamp-header-top .revamp-header-openinghours-container .revamp-header-openinghours h4 {
  color: #ffffff;
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 1px;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 600;
  font-weight: 800;
}
.lang-ar .c-header-revamp-component .revamp-header-top .revamp-header-openinghours-container .revamp-header-openinghours h4 {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  letter-spacing: 0px;
}
.c-header-revamp-component .revamp-header-bottom {
  background-color: #000000;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-nav-wrapper .revamp-header-nav-link {
  cursor: pointer;
  color: #ffffff;
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1.5px;
  line-height: 20px;
  font-style: normal;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-nav-wrapper .revamp-header-nav-link:hover {
  color: #FFEC00;
}
.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-nav-wrapper .revamp-header-nav-link {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  letter-spacing: 0px;
  font-size: 16px;
  font-weight: 700;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  padding: 0;
  display: inline-block;
  text-align: center;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  transform: skew(-17deg);
  text-decoration: none;
  cursor: pointer;
  position: relative;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  background-color: #e50000;
  border: 1px solid transparent;
  color: #ffffff;
  /* Medium devices (desktops, 1024px and up) */
  background-color: #ffffff;
  border: 1px solid transparent;
  color: #000000;
  /* Medium devices (desktops, 1024px and up) */
  background-color: #ffec00;
  position: relative;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-ru .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-zh .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
  font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  text-decoration: none;
  background-color: transparent;
  display: inline-block;
  transform: skew(17deg);
  color: inherit;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
body.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
  body.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
  body.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
  body.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-ru .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
body.lang-ru .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
  body.lang-ru .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
  body.lang-ru .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
  body.lang-ru .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-zh .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
body.lang-zh .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
  font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
  body.lang-zh .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
  body.lang-zh .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
  body.lang-zh .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:hover, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:focus,
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:hover,
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:focus {
    border-color: transparent;
    background-color: transparent;
    color: inherit;
    z-index: 3;
    opacity: 1;
    box-shadow: none;
    border: none;
  }
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:before, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:after {
  content: "";
  border-style: solid;
  border-color: #e50000;
  position: absolute;
  z-index: 0;
  box-sizing: content-box;
  transition: all 0.4s ease-in-out;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:before {
  width: 0;
  height: 100%;
  border-width: 1px 0 0 0;
  top: -1px;
  right: 0;
  transition-delay: 0s;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:after {
  width: 100%;
  height: 0;
  border-width: 0 1px 0 1px;
  top: -1px;
  left: -1px;
  transition-delay: 0s;
  z-index: -1;
}
.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:before {
  right: auto;
  left: 0;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
  color: inherit;
  transform: skew(17deg);
  padding: 10px 30px;
  z-index: 2;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:before, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:after,
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:before,
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-color: #e50000;
  transform: skew(-17deg);
  transition: all 0.4s ease-in-out;
  z-index: 0;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:before,
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:before {
  width: 0;
  height: 100%;
  border-width: 0 0 1px 0;
  bottom: -1px;
  left: 0;
  animation-direction: alternate;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:after,
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:after {
  width: 100%;
  height: 0;
  border-width: 0 1px 0 1px;
  bottom: -1px;
  right: 4px;
  transition-delay: 0s;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:hover,
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:hover {
  border: none;
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:hover, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:focus-within, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:focus-visible {
    background-color: #ffffff;
    color: #000000;
  }
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:hover:before, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:focus-within:before, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:focus-visible:before {
    width: 100%;
  }
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:hover:after, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:focus-within:after, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:focus-visible:after {
    height: calc(40% - 2px);
    transition-delay: 0.4s;
  }
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:hover > a:after, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:hover > button:after, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:focus-within > a:after, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:focus-within > button:after, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:focus-visible > a:after, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:focus-visible > button:after {
    height: calc(40% - 2px);
    transition-delay: 0.4s;
  }
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:hover > a:before, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:hover > button:before, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:focus-within > a:before, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:focus-within > button:before, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:focus-visible > a:before, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:focus-visible > button:before {
    width: 100%;
  }
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:before, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:after {
  border-color: #ffec00;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:before, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:after,
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:before,
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:after {
  border-color: #ffec00;
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:hover, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:focus, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:focus-visible, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:focus-within {
    background-color: #000000;
    color: #ffffff;
  }
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:focus, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:focus-visible, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:focus-within, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:hover, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:focus, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:focus-visible, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:focus-within, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:hover {
  outline: none !important;
  box-shadow: none !important;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:focus-visible:after, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:focus-visible:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 2px solid #3aaede;
  z-index: 2;
  pointer-events: none;
  transition: none !important;
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
    margin-right: 7px;
  }
  .lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
    margin-left: 7px;
    margin-right: 0px;
  }
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-top-logo {
  position: absolute;
  z-index: 1001;
  height: 84px;
  bottom: 8px;
  left: 20px;
  width: fit-content;
  /* Medium devices (desktops, 1024px and up) */
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-top-logo a, .c-header-revamp-component .revamp-header-bottom .revamp-header-top-logo a img {
  height: 100%;
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-top-logo {
    left: unset;
    right: 20px;
  }
}

.humburger-menu-open {
  position: relative;
}
.humburger-menu-open:not(.lang-ar) {
  animation: bodySlideLeftToRight 0.2s ease-in-out;
}
@keyframes bodySlideLeftToRight {
  from {
    left: 0;
  }
  to {
    left: 200px;
  }
}
.humburger-menu-open.lang-ar {
  animation: bodySlideRightToLeft 0.2s ease-in-out;
}
@keyframes bodySlideRightToLeft {
  from {
    right: 0;
  }
  to {
    right: 200px;
  }
}

.humburger-menu-close {
  position: relative;
}
.humburger-menu-close:not(.lang-ar) {
  animation: bodySlideCloseLeftToRight 0.2s ease-in-out;
}
@keyframes bodySlideCloseLeftToRight {
  from {
    left: 200px;
  }
  to {
    left: 0;
  }
}
.humburger-menu-close.lang-ar {
  animation: bodySlideCloseRightToLeft 0.2s ease-in-out;
}
@keyframes bodySlideCloseRightToLeft {
  from {
    right: 200px;
  }
  to {
    right: 0;
  }
}

/* < 768px */
@media (min-width: 320px) and (max-width: 767px) {
  .slider-close-button {
    position: absolute;
    top: 20px;
    z-index: 13;
    right: 20px;
  }
  .lang-ar .slider-close-button {
    left: 20px;
    right: auto;
  }
  .has-sub-menu .slider-close-button {
    position: fixed;
  }
  .c-header-revamp-component {
    display: flex;
    height: 60px;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    background: #000000;
    flex-shrink: 0;
    /* < 768px */
  }
}
@media (min-width: 320px) and (max-width: 767px) and (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component {
    height: 56px;
    background-color: #000000;
    padding: 16px 20px;
  }
  .c-header-revamp-component.mini-header {
    background-color: #333333;
    height: auto;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component svg {
    width: 24px;
    height: 20px;
  }
  .c-header-revamp-component picture {
    width: 85px;
    height: unset;
  }
  .c-header-revamp-component.mobile {
    z-index: 91;
  }
  .c-header-revamp-component.mobile .dark-logo {
    position: absolute;
    left: 50%;
    transform: translate(-50px, 0px);
  }
  .c-header-revamp-component.mobile .hamburger-slider .dark-logo {
    position: initial;
    transform: none;
  }
  .c-header-revamp-component .revamp-header-btn {
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    /* Medium devices (desktops, 1366px and up) */
    padding: 0;
    display: inline-block;
    text-align: center;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    transform: skew(-17deg);
    text-decoration: none;
    cursor: pointer;
    position: relative;
    opacity: 1;
    transition: all 0.4s ease-in-out;
    background-color: #ffec00;
    justify-content: center;
    align-items: center;
    transition: none;
    padding: 8px;
    transform: none;
    width: 83px;
    max-height: 36px;
    font-size: 12px;
    line-height: 20px;
    /* Medium devices (desktops, 1024px and up) */
  }
}
@media (min-width: 320px) and (max-width: 767px) and (min-width: 768px) {
  .c-header-revamp-component .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1366px) {
  .c-header-revamp-component .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  body.lang-ar .c-header-revamp-component .revamp-header-btn {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    /* Medium devices (desktops, 1366px and up) */
  }
}
@media (min-width: 320px) and (max-width: 767px) and (min-width: 768px) {
  body.lang-ar .c-header-revamp-component .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1024px) {
  body.lang-ar .c-header-revamp-component .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1366px) {
  body.lang-ar .c-header-revamp-component .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  body.lang-ru .c-header-revamp-component .revamp-header-btn {
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    /* Medium devices (desktops, 1366px and up) */
  }
}
@media (min-width: 320px) and (max-width: 767px) and (min-width: 768px) {
  body.lang-ru .c-header-revamp-component .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1024px) {
  body.lang-ru .c-header-revamp-component .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1366px) {
  body.lang-ru .c-header-revamp-component .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  body.lang-zh .c-header-revamp-component .revamp-header-btn {
    font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    /* Medium devices (desktops, 1366px and up) */
  }
}
@media (min-width: 320px) and (max-width: 767px) and (min-width: 768px) {
  body.lang-zh .c-header-revamp-component .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1024px) {
  body.lang-zh .c-header-revamp-component .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1366px) {
  body.lang-zh .c-header-revamp-component .revamp-header-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-btn a,
  .c-header-revamp-component .revamp-header-btn button {
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    /* Medium devices (desktops, 1366px and up) */
    text-decoration: none;
    background-color: transparent;
    display: inline-block;
    transform: skew(17deg);
    color: inherit;
    /* Medium devices (desktops, 1024px and up) */
  }
}
@media (min-width: 320px) and (max-width: 767px) and (min-width: 768px) {
  .c-header-revamp-component .revamp-header-btn a,
  .c-header-revamp-component .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-btn a,
  .c-header-revamp-component .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1366px) {
  .c-header-revamp-component .revamp-header-btn a,
  .c-header-revamp-component .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  body.lang-ar .c-header-revamp-component .revamp-header-btn a,
  body.lang-ar .c-header-revamp-component .revamp-header-btn button {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    /* Medium devices (desktops, 1366px and up) */
  }
}
@media (min-width: 320px) and (max-width: 767px) and (min-width: 768px) {
  body.lang-ar .c-header-revamp-component .revamp-header-btn a,
  body.lang-ar .c-header-revamp-component .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1024px) {
  body.lang-ar .c-header-revamp-component .revamp-header-btn a,
  body.lang-ar .c-header-revamp-component .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1366px) {
  body.lang-ar .c-header-revamp-component .revamp-header-btn a,
  body.lang-ar .c-header-revamp-component .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  body.lang-ru .c-header-revamp-component .revamp-header-btn a,
  body.lang-ru .c-header-revamp-component .revamp-header-btn button {
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    /* Medium devices (desktops, 1366px and up) */
  }
}
@media (min-width: 320px) and (max-width: 767px) and (min-width: 768px) {
  body.lang-ru .c-header-revamp-component .revamp-header-btn a,
  body.lang-ru .c-header-revamp-component .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1024px) {
  body.lang-ru .c-header-revamp-component .revamp-header-btn a,
  body.lang-ru .c-header-revamp-component .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1366px) {
  body.lang-ru .c-header-revamp-component .revamp-header-btn a,
  body.lang-ru .c-header-revamp-component .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  body.lang-zh .c-header-revamp-component .revamp-header-btn a,
  body.lang-zh .c-header-revamp-component .revamp-header-btn button {
    font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    /* Medium devices (desktops, 1366px and up) */
  }
}
@media (min-width: 320px) and (max-width: 767px) and (min-width: 768px) {
  body.lang-zh .c-header-revamp-component .revamp-header-btn a,
  body.lang-zh .c-header-revamp-component .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1024px) {
  body.lang-zh .c-header-revamp-component .revamp-header-btn a,
  body.lang-zh .c-header-revamp-component .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1366px) {
  body.lang-zh .c-header-revamp-component .revamp-header-btn a,
  body.lang-zh .c-header-revamp-component .revamp-header-btn button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-btn a:hover, .c-header-revamp-component .revamp-header-btn a:focus,
  .c-header-revamp-component .revamp-header-btn button:hover,
  .c-header-revamp-component .revamp-header-btn button:focus {
    border-color: transparent;
    background-color: transparent;
    color: inherit;
    z-index: 3;
    opacity: 1;
    box-shadow: none;
    border: none;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-btn a:before, .c-header-revamp-component .revamp-header-btn a:after,
  .c-header-revamp-component .revamp-header-btn button:before,
  .c-header-revamp-component .revamp-header-btn button:after {
    border: none;
    color: #000000;
  }
  .lang-ar .c-header-revamp-component .revamp-header-btn a,
  .lang-ar .c-header-revamp-component .revamp-header-btn button {
    letter-spacing: 0px !important;
  }
  .c-header-revamp-component .revamp-header-btn:before {
    content: none;
  }
  .c-header-revamp-component .revamp-header-btn:hover, .c-header-revamp-component .revamp-header-btn:focus {
    color: #000000;
    border: none;
  }
  .c-header-revamp-component .revamp-header-btn a {
    transform: none;
    font-size: 12px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-btn {
    margin-right: 7px;
  }
  .lang-ar .c-header-revamp-component .revamp-header-btn {
    margin-left: 7px;
    margin-right: 0px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .revamp-header-btn {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background: #ffec00;
  }
  .hamburger-menu {
    height: 100vh;
    width: 100vw;
    background-color: #000000;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 11;
  }
  body:not(.lang-ar) .hamburger-menu.opening {
    animation: openLeftToRight-english 0.2s linear;
  }
  @keyframes openLeftToRight-english {
    from {
      left: -100%;
    }
    to {
      left: 0%;
    }
  }
  .lang-ar .hamburger-menu.opening {
    animation: openRightToLeft-english 0.2s linear;
  }
  @keyframes openRightToLeft-english {
    from {
      right: -100%;
    }
    to {
      right: 0%;
    }
  }
  body:not(.lang-ar) .hamburger-menu.has-sub-menu, body:not(.lang-ar) .hamburger-menu.closing, body:not(.lang-ar) .hamburger-menu.has-sub-menu.closing .slider-content.open, body:not(.lang-ar) .hamburger-menu.has-sub-menu.closing .search-box-slider.open {
    left: -100%;
    animation: closeRightToLeft-english 0.2s linear;
  }
  @keyframes closeRightToLeft-english {
    from {
      left: 0%;
    }
    to {
      left: -100%;
    }
  }
  .lang-ar .hamburger-menu.has-sub-menu, .lang-ar .hamburger-menu.closing, .lang-ar .hamburger-menu.has-sub-menu.closing .slider-content.open, .lang-ar .hamburger-menu.has-sub-menu.closing .search-box-slider.open {
    right: -100%;
    animation: closeLeftToRight-english 0.2s linear;
  }
  @keyframes closeLeftToRight-english {
    from {
      right: 0%;
    }
    to {
      right: -100%;
    }
  }
  .hamburger-menu .slider-name {
    width: 100%;
    display: flex;
    padding: 14px 20px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    align-self: stretch;
    color: #ffffff;
    font-feature-settings: "liga" off, "clig" off;
    /* English/Desktop/Heading/s */
    font-family: "Norwester", "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
  .hamburger-menu .slider-name .profile-title {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #ffec00;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: 0px;
  }
  .hamburger-menu .slider-name .profile-title svg {
    margin-top: 5px;
  }
  .lang-ar .hamburger-menu .slider-name .profile-title svg {
    transform: rotate(0deg);
  }
  .lang-ar .hamburger-menu .slider-name .profile-title {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
    font-weight: 700;
  }
  .hamburger-menu .slider-name .profile-title .profile-title svg {
    margin-top: 2px;
  }
  .hamburger-menu .slider-name .profile-title .non-loggedin-placeholder {
    margin-top: 5px;
    color: white;
  }
  .lang-ar .hamburger-menu .slider-name {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
    letter-spacing: 0px;
    font-weight: 700;
  }
  .lang-ar .hamburger-menu .slider-name svg {
    transform: rotate(180deg);
  }
  .hamburger-menu .slider-name.profile-title {
    padding: 11px 20px 11px 16px;
  }
  .lang-ar .hamburger-menu .slider-name.profile-title {
    padding: 11px 20px 11px 19px;
  }
  .lang-ar .hamburger-menu .slider-name {
    font-weight: 700;
  }
  .hamburger-menu .slider-content {
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    position: fixed;
    z-index: 12;
    top: 0px;
    right: 0px;
  }
  body:not(.lang-ar) .hamburger-menu .slider-content.open {
    right: 0%;
    animation: openRightToLeft-english 0.2s linear;
  }
  @keyframes openRightToLeft-english {
    from {
      right: -100%;
    }
    to {
      right: 0%;
    }
  }
  .lang-ar .hamburger-menu .slider-content.open {
    left: 0%;
    right: auto;
    animation: openLeftToRight-english 0.2s linear;
  }
  @keyframes openLeftToRight-english {
    from {
      left: -100%;
    }
    to {
      left: 0%;
    }
  }
  body:not(.lang-ar) .hamburger-menu .slider-content.close {
    right: -100%;
    animation: closeLeftToRight-english 0.2s linear;
  }
  @keyframes closeLeftToRight-english {
    from {
      right: 0%;
    }
    to {
      right: -100%;
    }
  }
  .lang-ar .hamburger-menu .slider-content.close {
    left: -100%;
    right: auto;
    animation: closeRightToLeft-english 0.2s linear;
  }
  @keyframes closeRightToLeft-english {
    from {
      left: 0%;
    }
    to {
      left: -100%;
    }
  }
  .hamburger-menu .slider-content .profile-slider {
    background-color: #333333;
  }
  .hamburger-menu .slider-content .slider-header {
    display: flex;
    height: 60px;
    padding: 12px 20px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    background-color: #333333;
  }
  .lang-ar .hamburger-menu .slider-content .slider-header span svg {
    transform: rotate(180deg);
  }
  .hamburger-menu .slider-content .slider-title {
    display: flex;
    padding: 14px 20px;
    margin: 16px 0px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: #ffffff;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Norwester;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    border-bottom: 0.5px solid #d3d3d3;
  }
  .lang-ar .hamburger-menu .slider-content .slider-title {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .hamburger-menu .slider-content .slider-title .profile-title {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #ffec00;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: 0px;
    font-weight: 700;
  }
  .lang-ar .hamburger-menu .slider-content .slider-title .profile-title {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .hamburger-menu .slider-content .slider-title .profile-title svg {
    margin-top: 2px;
  }
  .hamburger-menu .slider-content .slider-list li {
    display: flex;
    padding: 11px 40px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    align-self: stretch;
    color: #ffffff;
    font-feature-settings: "liga" off, "clig" off;
    /* English/Mobile/Body/Regular/m */
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
    letter-spacing: 0px;
    text-transform: capitalize;
  }
  .hamburger-menu .slider-content .slider-list li a {
    color: #ffffff;
  }
  .hamburger-menu .slider-content .slider-list li:hover {
    background-color: #333333;
  }
  .lang-ar .hamburger-menu .slider-content .slider-list li {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .hamburger-menu .slider-content .slider-list li .helper-upcoming {
    display: flex;
    padding: 0px 4px 1px 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background-color: #e50000;
    color: white;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
  }
  .hamburger-menu .mb-nav-links-bottom {
    border-top: 1px solid #333333;
  }
  .hamburger-menu .mb-nav-links-bottom .mb-language-selector-container {
    color: #ffffff;
    letter-spacing: normal;
    line-height: 26px;
  }
  .hamburger-menu .mb-nav-links-bottom .language-selector-capsule {
    border: 1px solid #d3d3d3;
  }
  .hamburger-menu .mb-nav-links-bottom .language-selector-capsule.selected {
    color: #000000;
    background: #ffffff;
  }
  .hamburger-slider {
    background-image: url("../../../assets/img/bg-footer.jpg");
    padding-bottom: 16px;
  }
  .hamburger-slider .hamburger-slider-header {
    padding: 12px 20px;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .hamburger-slider .hamburger-slider-header.opening-hour-div {
    padding: 14px 20px;
    width: 100%;
  }
  .lang-ar .hamburger-slider .hamburger-slider-header svg {
    transform: rotate(180deg);
  }
  .hamburger-slider .hamburger-slider-header .openinghours {
    width: 100%;
  }
  .hamburger-slider .hamburger-slider-header .openinghours .revamp-header-openinghours {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .hamburger-slider .hamburger-slider-header .openinghours .revamp-header-openinghours h4 {
    color: #ffffff;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .lang-ar .hamburger-slider .hamburger-slider-header .openinghours .revamp-header-openinghours h4 {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
    letter-spacing: 0px;
  }
  .hamburger-slider .hamburger-slider-header .searchBox {
    display: flex;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;
  }
  .hamburger-slider .hamburger-slider-header .searchBox p {
    color: #ffffff;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
    letter-spacing: 0px;
  }
  .lang-ar .hamburger-slider .hamburger-slider-header .searchBox p {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
    font-weight: 700;
  }
  .lang-ar .hamburger-slider .hamburger-slider-header .searchBox {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .lang-ar .hamburger-slider .hamburger-slider-header .searchBox svg {
    transform: rotate(0deg);
  }
  .hamburger-slider .hamburger-slider-header.searchBox {
    padding: 11px 16px;
  }
  .hamburger-slider .hamburger-slider-header picture {
    width: 85px;
    left: -10px;
    height: 100%;
  }
  .lang-ar .hamburger-slider .hamburger-slider-header picture {
    right: -10px;
    left: unset;
  }
  .hamburger-slider .search-box-slider {
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 12;
  }
  body:not(.lang-ar) .hamburger-slider .search-box-slider.open {
    right: 0%;
    animation: openRightToLeft-english 0.2s linear;
  }
  @keyframes openRightToLeft-english {
    from {
      right: -100%;
    }
    to {
      right: 0%;
    }
  }
  .lang-ar .hamburger-slider .search-box-slider.open {
    left: 0%;
    right: auto;
    animation: openLeftToRight-english 0.2s linear;
  }
  @keyframes openLeftToRight-english {
    from {
      left: -100%;
    }
    to {
      left: 0%;
    }
  }
  body:not(.lang-ar) .hamburger-slider .search-box-slider.close {
    right: -100%;
    animation: closeLeftToRight-english 0.2s linear;
  }
  @keyframes closeLeftToRight-english {
    from {
      right: 0%;
    }
    to {
      right: -100%;
    }
  }
  .lang-ar .hamburger-slider .search-box-slider.close {
    left: -100%;
    right: auto;
    animation: closeRightToLeft-english 0.2s linear;
  }
  @keyframes closeRightToLeft-english {
    from {
      left: 0%;
    }
    to {
      left: -100%;
    }
  }
  .hamburger-slider .search-box-slider .header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #333333;
  }
  .hamburger-slider .search-box-slider .header .search-header {
    display: flex;
    height: 60px;
    padding: 12px 20px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
  }
  .hamburger-slider .search-box-slider .header .search-header span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lang-ar .hamburger-slider .search-box-slider .header .search-header span svg {
    transform: rotate(180deg);
  }
  .hamburger-slider .search-box-slider .header .search-input {
    display: flex;
    height: 48px;
    padding: 11px 20px;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-bottom: 0.5px solid #d3d3d3;
  }
  .hamburger-slider .search-box-slider .header .search-input input {
    background: transparent;
    width: 100%;
    color: #ffffff;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0px;
  }
  .lang-ar .hamburger-slider .search-box-slider .header .search-input input {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .hamburger-slider .search-box-slider .header .search-input input::placeholder {
    color: #ffffff;
  }
  .hamburger-slider .search-box-slider .header .search-input svg path {
    stroke: #ffffff;
  }
  .hamburger-slider .search-box-slider .popular-search {
    gap: 0px;
    padding-top: 16px;
  }
  .hamburger-slider .search-box-slider .popular-search.suggestion {
    background-color: #f8f8f8;
    color: #000000;
    height: fit-content;
  }
  .hamburger-slider .search-box-slider .popular-search.suggestion .search-list {
    height: fit-content;
  }
  .hamburger-slider .search-box-slider .popular-search.suggestion .search-list .list-item {
    cursor: pointer;
  }
  .hamburger-slider .search-box-slider .popular-search.suggestion .search-list .list-item .list-item-box svg path {
    stroke: #000000;
  }
  .hamburger-slider .search-box-slider .popular-search.suggestion .search-list .list-item .list-item-text {
    color: #000000;
    font-weight: 400;
  }
  .hamburger-slider .search-box-slider .popular-search .popular-title {
    display: flex;
    height: 48px;
    padding: 11px 40px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    color: #ffffff;
  }
  .hamburger-slider .search-box-slider .popular-search .popular-title svg {
    margin-top: 5px;
  }
  .lang-ar .hamburger-slider .search-box-slider .popular-search .popular-title {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .hamburger-slider .search-box-slider .popular-search .search-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    align-self: stretch;
    height: fit-content;
  }
  .hamburger-slider .search-box-slider .popular-search .search-list .list-item {
    display: flex;
    height: 48px;
    padding: 11px 40px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
  .hamburger-slider .search-box-slider .popular-search .search-list .list-item .list-item-box {
    color: #ffffff;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0px;
  }
  .lang-ar .hamburger-slider .search-box-slider .popular-search .search-list .list-item .list-item-box {
    transform: rotate(180deg);
  }
  .hamburger-slider .search-box-slider .popular-search .search-list .list-item .list-item-box svg path {
    stroke: #ffffff;
  }
  .hamburger-slider .search-box-slider .popular-search .search-list .list-item .list-item-text {
    color: #ffffff;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    line-height: 26px;
    letter-spacing: 0px;
  }
  .lang-ar .hamburger-slider .search-box-slider .popular-search .search-list .list-item .list-item-text {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .navlinkDiv {
    margin-top: 16px;
    margin-bottom: 16px;
    /* < 1023px */
  }
}
@media (min-width: 320px) and (max-width: 767px) and (min-width: 320px) and (max-width: 1024px) {
  .navlinkDiv {
    overflow-y: scroll;
    max-height: 45vh;
  }
  .navlinkDiv .navlinkDiv ul {
    list-style-type: none; /* Removes bullet points */
    margin: 0; /* Removes default margin */
    padding: 0; /* Removes default padding */
  }
}
.revamp-header-notice-component.mobile {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .revamp-header-notice-component.mobile {
    display: none;
  }
}

.article-component.farah-main .article-list .article-list-text .v-dynamic-product-pricing h3 {
  color: #000;
}

.article-component.farah-main .article-list .article-list-text .v-dynamic-product-pricing p {
  text-align: center;
}

/*
 *  Radius
*/
/*
 * Config
 * @ Variables
 *
*/
/*
 *  Use Defaults
*/
/*
 *  FWAD base Font size
*/
/*
 * H1
*/
/*
 * header fonts mobile devices
 */
/*
 * header fonts tablets(portrait)
 */
/*
 * header fonts tablets(landscape) and small desktop
 */
/*
 * header fonts large desktop
 */
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*/*
 * body-2
*/
/*
 * body-3
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ar-1
*/
/*/*
 * body-ar-2
*/
/*
 * body-ar-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ru-1
*/
/*/*
 * body-ru-2
*/
/*
 * body-ru-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-zh-1
*/
/*/*
 * body-zh-2
*/
/*
 * body-zh-3
*/
/*
* z-index variables
*/
/*
* form placeholder color variables
*/
/*
 * Background Image
 */
/*
  * Line height font
  */
/*
  * Border Radius
  */
/*
  * Box Sizing
  */
/*
  * Box Shadow
  */
/*
  * Border Radius Separate
  */
/*
  * Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * large screen Desktop (PC's)
  */
/*
  * Wide screen Devices
  */
/*
  * upto Tablet
  */
/*
  * Tablet
  */
/*
  * TabletOnly
  */
/*
  * Mobile
  */
/*
  * Transition
     usage example
       a{
       color: gray;
       @include transition(color .3s ease);
       &:hover {
         color: black;
       }
 }
  */
/*
  * Opacity
  */
/*
  * Clearfix
  */
/*
  * Configure vendor
  */
/*
  * Transform
  */
/**
 *   Use Arrow inside the :after and :before pseudo elements
 *   and change the position as per needed
 *   @include arrows('right', 7px, 7px, #000);
 **/
/**
 *   Background gradient mixin
 **/
/*
  * Flex
  */
/*
 * @ Fonts
 *
*/
/*
 * Base
 * @ Colors
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
.c-header-revamp-component .container {
  width: 100vw;
  height: 336px;
  padding: 32px 10px;
  background: #f8f8f8;
  box-shadow: 0px 1px 0px #d3d3d3;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 35px;
  left: 0px;
  z-index: 1000;
}
.c-header-revamp-component .relative-box {
  width: 24px;
  height: 24px;
  position: relative;
}
.c-header-revamp-component .relative-box-inner {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0.69px;
  top: 12px;
  transform: rotate(-45deg);
  transform-origin: 0 0;
  border: 1.5px solid #808080;
}
.c-header-revamp-component .content {
  height: 272px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
}
.c-header-revamp-component .image-container {
  width: 672px;
  height: 52px;
  transform: skew(-17deg);
  border: 1px solid #d3d3d3;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  gap: 8px;
}
.c-header-revamp-component .image-container svg {
  width: fit-content;
  transform: skew(17deg);
}
.c-header-revamp-component .image-container input {
  align-items: center;
  transform: skew(17deg);
  width: 100%;
  background-color: transparent;
  color: #000000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
}
.lang-ar .c-header-revamp-component .image-container input {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-header-revamp-component .image-container .autocomplete-wrapper {
  width: 100%;
}
.c-header-revamp-component .image-container .autocomplete-wrapper form {
  border: none;
}
.c-header-revamp-component .image-container .autocomplete-wrapper form .btn-reset {
  right: 0;
  position: absolute;
  padding-left: 0px;
  padding-right: 0px;
  top: 5px;
}
.c-header-revamp-component .image-container .autocomplete-wrapper form .btn-reset svg {
  width: 12px;
  height: 12px;
  fill: #e50000;
}
.c-header-revamp-component .image-container .autocomplete-wrapper form .search-trigger {
  display: none;
}
.c-header-revamp-component .image-container.searchEnabled {
  border: 1px solid #333;
}
.c-header-revamp-component .image {
  width: 672px;
  height: 52px;
  position: absolute;
  border: 1px solid #d3d3d3;
}
.c-header-revamp-component .search-bar {
  position: absolute;
  left: 30px;
  top: 13px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.c-header-revamp-component .search-box {
  width: 24px;
  height: 24px;
  position: relative;
}
.c-header-revamp-component .search-box-inner {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 3.9px;
  top: 3.74px;
  border: 1.5px solid #808080;
}
.c-header-revamp-component .search-text {
  color: #808080;
  font-size: 16px;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-weight: 700;
  line-height: 26px;
  word-wrap: break-word;
}
.c-header-revamp-component .popular-search {
  align-self: stretch;
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
}
.c-header-revamp-component .popular-title {
  align-self: stretch;
  color: #808080;
  font-size: 16px;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-weight: 400;
  line-height: 26px;
  word-wrap: break-word;
  letter-spacing: 0px;
}
.lang-ar .c-header-revamp-component .popular-title {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-header-revamp-component .search-list {
  align-self: stretch;
  height: 152px;
  padding-left: 1px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
}
.lang-ar .c-header-revamp-component .search-list {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-header-revamp-component .search-list.popular-search-term .list-item .list-item-text {
  font-weight: 700;
}
.c-header-revamp-component .list-item {
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.c-header-revamp-component .list-item-box {
  width: 20px;
  height: 20px;
  position: relative;
}
.lang-ar .c-header-revamp-component .list-item-box {
  transform: rotate(180deg);
}
.c-header-revamp-component .list-item-box-inner {
  width: 13.33px;
  height: 10.67px;
  position: absolute;
  left: 3.33px;
  top: 4.67px;
  border: 1.5px solid #333333;
}
.c-header-revamp-component .list-item-text {
  color: #333333;
  font-size: 16px;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 26px;
  word-wrap: break-word;
  font-feature-settings: "liga" off, "clig" off;
  font-style: normal;
  letter-spacing: 0px;
}
.lang-ar .c-header-revamp-component .list-item-text {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-header-revamp-component .search-close {
  position: absolute;
  right: 36px;
  width: fit-content;
  top: 20.4px;
  cursor: pointer;
  width: 24px;
  height: 25px;
}
.lang-ar .c-header-revamp-component .search-close {
  left: 36px;
  right: auto;
}
.c-header-revamp-component .content .image-container svg {
  width: 25px;
  height: 24px;
  cursor: pointer;
}
.c-header-revamp-component .content .image-container .search-icon-desktop-wrap.enableSearch {
  cursor: pointer;
}
.c-header-revamp-component .search-component-icon {
  cursor: pointer;
}
.c-header-revamp-component .search-component-icon svg {
  width: 24px;
  height: 24px;
}
.c-header-revamp-component .search-component .container .search-close {
  cursor: pointer;
}
.c-header-revamp-component .search-component .container .content .image-container .search-close-button {
  cursor: pointer;
}
.c-header-revamp-component .clear-search-button {
  cursor: pointer;
}
.c-header-revamp-component .results-container {
  transform: skew(17deg);
}
.c-header-revamp-component .results-container .autosuggest-list {
  margin-top: 30px;
  background-color: #f8f8f8;
}

/*
 *  Radius
*/
/*
 * Config
 * @ Variables
 *
*/
/*
 *  Use Defaults
*/
/*
 *  FWAD base Font size
*/
/*
 * H1
*/
/*
 * header fonts mobile devices
 */
/*
 * header fonts tablets(portrait)
 */
/*
 * header fonts tablets(landscape) and small desktop
 */
/*
 * header fonts large desktop
 */
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*/*
 * body-2
*/
/*
 * body-3
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ar-1
*/
/*/*
 * body-ar-2
*/
/*
 * body-ar-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ru-1
*/
/*/*
 * body-ru-2
*/
/*
 * body-ru-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-zh-1
*/
/*/*
 * body-zh-2
*/
/*
 * body-zh-3
*/
/*
* z-index variables
*/
/*
* form placeholder color variables
*/
/*
 * Background Image
 */
/*
  * Line height font
  */
/*
  * Border Radius
  */
/*
  * Box Sizing
  */
/*
  * Box Shadow
  */
/*
  * Border Radius Separate
  */
/*
  * Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * large screen Desktop (PC's)
  */
/*
  * Wide screen Devices
  */
/*
  * upto Tablet
  */
/*
  * Tablet
  */
/*
  * TabletOnly
  */
/*
  * Mobile
  */
/*
  * Transition
     usage example
       a{
       color: gray;
       @include transition(color .3s ease);
       &:hover {
         color: black;
       }
 }
  */
/*
  * Opacity
  */
/*
  * Clearfix
  */
/*
  * Configure vendor
  */
/*
  * Transform
  */
/**
 *   Use Arrow inside the :after and :before pseudo elements
 *   and change the position as per needed
 *   @include arrows('right', 7px, 7px, #000);
 **/
/**
 *   Background gradient mixin
 **/
/*
  * Flex
  */
/*
 * @ Fonts
 *
*/
/*
 * Base
 * @ Colors
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
.tenant-fwad .email-verification__cookie {
  padding: 14px 115px;
  z-index: 9999;
  background: #f7f7f7;
  width: 100%;
  display: flex;
  align-items: center;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-fwad .email-verification__cookie {
    padding: 16px 17px;
  }
}
.tenant-fwad .email-verification__cookie__content {
  display: flex;
  align-items: center;
  flex: 1;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-fwad .email-verification__cookie__content {
    align-items: flex-start;
    flex-direction: column;
  }
}
.tenant-fwad .email-verification__cookie__content__description {
  color: #333333;
  font-family: Open Sans;
  font-size: 16px;
  letter-spacing: normal;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  flex: 1;
}
.tenant-fwad .email-verification__cookie__content__action a {
  color: #000000;
  border-bottom: 1px solid #e50000;
  text-decoration: none;
  padding-bottom: 1px;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  cursor: pointer;
  margin-left: 24px;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-fwad .email-verification__cookie__content__action a {
    margin-left: 0;
    margin-top: 16px;
  }
}
.tenant-fwad .email-verification__cookie__close {
  height: 12px;
  width: 12px;
  margin-left: 48px;
}
.tenant-fwad .c-cookies.component {
  padding: 7px 14px;
  text-align: left;
  margin: auto;
  position: fixed;
  bottom: 56px;
  width: 100%;
  opacity: 0.95;
  background: #ffffff;
  z-index: 90;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-fwad .c-cookies.component {
    padding: 10px 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-cookies.component {
    padding: 20px 35px;
    bottom: 0;
  }
}
.tenant-fwad .c-cookies.revamp-header-notice-component {
  position: relative;
  bottom: auto;
  display: flex;
  padding: 12px 35px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  /* < 1023px */
}
.tenant-fwad .c-cookies.revamp-header-notice-component span svg {
  width: 25px;
  height: 24px;
}
.tenant-fwad .c-cookies.revamp-header-notice-component p {
  color: #000000;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .tenant-fwad .c-cookies.revamp-header-notice-component p {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
.lang-ar .tenant-fwad .c-cookies.revamp-header-notice-component p {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  text-align: right;
}
.tenant-fwad .c-cookies.revamp-header-notice-component p a {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}
.tenant-fwad .c-cookies.revamp-header-notice-component p a:hover {
  color: inherit;
}
@media (min-width: 320px) and (max-width: 1024px) {
  .tenant-fwad .c-cookies.revamp-header-notice-component {
    padding: 12px 20px;
  }
}
.tenant-fwad .c-cookies.revamp-header-notice-component .revamp-header-note {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 8px;
}
.tenant-fwad .c-cookies.revamp-header-notice-component .close-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.purchase-cookie .tenant-fwad .c-cookies {
  bottom: 0;
}
.purchase-cookie .tenant-fwad .c-cookies-minicart {
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .purchase-cookie .tenant-fwad .c-cookies-minicart {
    bottom: 82px;
  }
}
.purchase-cookie .tenant-fwad .c-cookies-step-2-cta-sticky {
  bottom: 60px;
  /* Medium devices (desktops, 1024px and up) */
}
.purchase-cookie .tenant-fwad .c-cookies-step-2-cta-sticky.confirmation-error {
  bottom: 0;
}
@media only screen and (min-width: 1024px) {
  .purchase-cookie .tenant-fwad .c-cookies-step-2-cta-sticky {
    bottom: 0;
  }
}
.tenant-fwad .c-cookies .w--content {
  text-align: left;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-fwad .c-cookies .w--content {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-cookies .w--content {
    margin: 0;
    max-width: 100%;
  }
}
.tenant-fwad .c-cookies .w--content .cookie-description {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #333333;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.1px;
  margin-bottom: 12px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-fwad .c-cookies .w--content .cookie-description {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-cookies .w--content .cookie-description {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-fwad .c-cookies .w--content .cookie-description {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-ar .tenant-fwad .c-cookies .w--content .cookie-description {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .tenant-fwad .c-cookies .w--content .cookie-description {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .tenant-fwad .c-cookies .w--content .cookie-description {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .tenant-fwad .c-cookies .w--content .cookie-description {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-ru .tenant-fwad .c-cookies .w--content .cookie-description {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .tenant-fwad .c-cookies .w--content .cookie-description {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .tenant-fwad .c-cookies .w--content .cookie-description {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .tenant-fwad .c-cookies .w--content .cookie-description {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-zh .tenant-fwad .c-cookies .w--content .cookie-description {
  font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 300;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .tenant-fwad .c-cookies .w--content .cookie-description {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .tenant-fwad .c-cookies .w--content .cookie-description {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .tenant-fwad .c-cookies .w--content .cookie-description {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media (min-width: 768px) {
  .tenant-fwad .c-cookies .w--content .cookie-description {
    max-width: 80%;
    text-align: left;
    letter-spacing: 0.1px;
    line-height: 18px;
    margin-bottom: 0;
    font-size: 12px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-cookies .w--content .cookie-description {
    line-height: 14px;
    font-size: 12px;
    max-width: 87%;
  }
}
.tenant-fwad .c-cookies .w--content .cookie-description a {
  text-decoration: none;
}
.tenant-fwad .c-cookies .w--content .cookie-accept {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  position: relative;
  color: #000000;
  border-bottom: 1px solid #e50000;
  text-decoration: none;
  padding: 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  cursor: pointer;
  color: #000000;
  max-width: 61px;
  padding-top: 0;
  margin: 0;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 2px;
  line-height: 14px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-ar .tenant-fwad .c-cookies .w--content .cookie-accept {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-ru .tenant-fwad .c-cookies .w--content .cookie-accept {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-zh .tenant-fwad .c-cookies .w--content .cookie-accept {
  font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media (min-width: 768px) {
  .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-ar .tenant-fwad .c-cookies .w--content .cookie-accept {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-ru .tenant-fwad .c-cookies .w--content .cookie-accept {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
body.lang-zh .tenant-fwad .c-cookies .w--content .cookie-accept {
  font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .tenant-fwad .c-cookies .w--content .cookie-accept {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}
.tenant-fwad .c-cookies .w--content .cookie-accept:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 1px;
  bottom: -1px;
  left: -12px;
  z-index: 1;
  border-bottom: 1px solid #ffffff;
}
.tenant-fwad .c-cookies .w--content .cookie-accept:hover, .tenant-fwad .c-cookies .w--content .cookie-accept:focus {
  color: #000000;
  border: none;
  border-bottom: 1px solid #e50000;
}
.tenant-fwad .c-cookies .w--content .cookie-accept:hover:before, .tenant-fwad .c-cookies .w--content .cookie-accept:focus:before {
  left: 100%;
  transition: all 0.4s ease-in-out;
}
@media (min-width: 768px) {
  .tenant-fwad .c-cookies .w--content .cookie-accept {
    position: absolute;
    top: 24px;
    right: 87px;
    cursor: pointer;
    font-size: 13px;
    letter-spacing: 2px;
    line-height: 14px;
  }
  .tenant-fwad .c-cookies .w--content .cookie-accept:after {
    content: "";
    border-right: 1px solid #d3d3d3;
    height: 21px;
    position: absolute;
    top: 0;
    right: -24px;
  }
}
.tenant-fwad .c-cookies .w--content .cookie-accept:focus {
  white-space: nowrap;
}
.tenant-fwad .c-cookies .w--content .cookie-close-icon {
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 5px;
  top: 5px;
  line-height: normal;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  cursor: pointer;
}
@media (min-width: 768px) {
  .tenant-fwad .c-cookies .w--content .cookie-close-icon {
    top: 27px;
    right: 20px;
    width: 14px;
    height: 14px;
    line-height: 24px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .c-cookies .w--content .cookie-close-icon {
    line-height: 25px;
  }
}
.tenant-fwad .c-cookies .w--content .cookie-close-icon svg use {
  stroke: #333333;
}

.c-header-revamp-component {
  /* < 1023px */
}
.c-header-revamp-component.desktop {
  display: block;
}
.c-header-revamp-component.mobile {
  display: none;
}
@media (min-width: 320px) and (max-width: 1024px) {
  .c-header-revamp-component.mobile {
    display: flex;
  }
  .c-header-revamp-component.desktop {
    display: none;
  }
}